import * as React from "react";
import Layout from "../../components/layout";
import { FocusAreaIcon } from "../../components/svg";
import { Helmet } from "react-helmet";
import FintechId from "../../images/FintechId.png";

const FintechPage = () => (
  <Layout>
    <Helmet title="Digital Health - Levercode" />
    {/* Sub Header image */}
    <div className="_fintech_hero mt-32">
      <div className="flex">
        <FocusAreaIcon icon="fintech" className="mx-auto w-auto h-32 sm:h-80 -mt-20 text-yellow-800 fill-current" />
      </div>

      {/* Sub Header intro text */}
      <div className="mx-auto py-12 sm:py-24 text-white">
        <h1 className="text-4xl sm:text-7xl leading-normal font-bold py-10 text-center">
          <span className="whitespace-nowrap">
            <span className="text-yellow-800 text-5xl sm:text-8xl">[</span> Fintech <span className="text-yellow-800 text-5xl sm:text-8xl">]</span>
          </span>
        </h1>
        <p className="sm:px-2/12 text-xl sm:text-3xl leading-relaxed text-center pb-40">Levercode’s Approach to Fintech</p>
      </div>
    </div>
    {/* Content */}
    <div className="container max-w-screen-xl py-32 text-2xl">
      {/*<h2 className="text-5xl text-blue-800 ">Fintech by Levercode</h2>*/}
      <p>
        Financial Management and Services are the areas that have reaped tremendous benefits from technological advancements. Besides making it faster and more convenient to transfer funds from one entity to another, digital currencies (such as Bitcoin,
        Ethereum etc) have opened a whole new array of opportunities for transferring of value, ownership, and accumulating wealth.
      </p>

      <p className="mt-16 font-bold border-l-4 border-blue-800 pl-8 text-black">
        In order to prove ownership of digital assets, Levercode has created digital identity and digital signature platform, which covers the fintech technological vertical and is also applicable to all other verticals in the economy.
      </p>

      <p className="mt-16">
        Integrating a digital identity solution into your systems enable users to have a single digital identity, which can be utilized to authenticate users in different environments, reducing the need for manual labor and in-turn giving you the
        possibility to <strong>securely authenticate</strong> your employees and clients.
      </p>

      <p className="mt-16">
        Although <strong>digital signatures</strong> give a noticeable effect to your clients experience in authenticating and committing proven transactions, it also has an effect on your internal <strong>systems communication integrity</strong>. Imagine
        having an identity related to every movement on data in you mission critical systems. Being able to reduce the risk of an insider attack is well worth the investment.
      </p>

      <p className="mt-16">
        <img src={FintechId} alt="Levercode Digital Identity" />
      </p>

      <h2 className="text-5xl text-blue-800 mt-24">Directed Acyclic Graph (DAG)</h2>

      <p className="mt-16">
        DAG is another layer of added security can be achieved through logging of events. <strong>An immutable log</strong> is a reliable tool to mitigate insider attacks from altering log data within a company by having a DAG based logging system.
        Witnesses are depended on as trusted nodes in order to track events, any event that is logged without Witness nodes is considered an untrusted event and is discarded from the log ledger.
      </p>

      <p className="mt-16 font-bold border-l-4 border-blue-800 pl-8 text-black">
        With extensive experience in creating and managing large payment systems and its relevant subsystems, Levercode can provide a fully functional Proof of Stake DAG based digitized payment systems and virtual payment instruments.
      </p>

      <p className="mt-16">
        Initial designs and UI components are custom made for clients in order to brand the product to their requirements. Levercode is able to provide the client with a custom configuration enabled by the functionality of the DAG system. Configuring the
        DAG network has a massive impact on the end result of the product. It is possible to configure a plethora of parameters to meet the customers needs.
      </p>

      <p className="mt-16">
        High speed, global coverage and ease of making transaction in digital or virtual form have brought on the radar new critical issues such as <strong>reliability</strong>, <strong>security</strong>, <strong>transparency</strong>,{" "}
        <strong>legality</strong>, and <strong>integrity</strong> of the environment in which transactions take place.  It is important to secure that only transactions that are based on the willful action of the owner of the asset and{" "}
        <strong>are in compliance with laws and regulations (such as GDPR, KYC, AML etc)</strong> are executed and the others are put on hold or get blocked. This is possible only when the system is intelligent enough to filter out allowed and prohibited
        transactions based on risk criteria.
      </p>

      <p className="mt-16">
        As important as the overall environment is the technology platform that is used for executing and recording events and transactions. That <strong>sets very high standards</strong> for systems designed by Levercode. Such systems must be reliable,
        work fast, <strong>provide protection against attack vectors and proof of transactions</strong>. Utilization of digital signatures, Blockchain, DAG or other forms of Distributed Ledger Technology has proved instrumental in achieving this goal and
        providing provenance of data.
      </p>

      <p className="mt-16">
        As important as the overall environment is the technology platform that is used for executing and recording events and transactions. That sets very high standards for systems designed by Levercode. Such systems must be reliable, work fast, provide
        protection against attack vectors and proof of transactions. Utilization of digital signatures, Blockchain, DAG or other forms of Distributed Ledger Technology has proved instrumental in achieving this goal and providing provenance of data.
      </p>

      <div className=" bg-gray-100 p-16 mt-16">
        <h3 className="text-blue-800 text-4xl">In the Fintech vertical, Levercode creates value by:</h3>

        <ul className="list-disc leading-normal mx-16 mt-12">
          <li className="mt-6">Integrating digital authentication and signature platforms</li>
          <li className="mt-6">Providing immutable logging and proof of events by implementation of Blockchain, DAG or other DLT </li>
          <li className="mt-6">White labeling and creating DAG based messaging systems and wallets for executing transactions and recording the events</li>
          <li className="mt-6">Designing platform for effective launch and governance of virtual currency</li>
          <li className="mt-6">Securing compliance of your data systems with regulatory environment</li>
          <li className="mt-6">Offering excellent customer service in setting up the systems and keeping them operational and updated over time</li>
        </ul>
      </div>
    </div>
  </Layout>
);

export default FintechPage;
